<template>
  <div class="item">
    <div class="item__container">
      <div class="item__photos" ref="photos">
        <button
          v-if="galleryScrollLeft < (itemData.pictures.length - 2) * 300"
          class="item__arrow"
          @click="scrollGalleryRight">
          <i class="fal fa-chevron-right"/>
        </button>
        <button
          v-if="galleryScrollLeft > 0"
          class="item__arrow item__arrow--left"
          @click="scrollGalleryLeft">
          <i class="fal fa-chevron-left"/>
        </button>
        <div
          class="item__photosContainer"
          id="gallery"
          @scroll="onGalleryScroll">
          <div
            v-for="(photo, photoIndex) in itemData.pictures"
            :key="`photo_${photoIndex}`"
            @click="$store.commit('setOpenImageIndex', photoIndex)"
            class="item__photo">
            <v-lazy-image
              v-if="'packagedfood|freshfood|fashion|cosmetics|homeware|productoffering'.includes(itemData.item_type)"
              :src="imgix(photo, { width: 300, height: 300 })"
              :src-placeholder="imgix(photo, { width: 30, height: 30 })"
              width="300"
              height="300"
              style="object-fit: contain;"
            />
            <v-lazy-image
              v-else
              :src="imgix(photo, { width: 600, height: 600 })"
              :src-placeholder="imgix(photo, { width: 30, height: 30 })"
              width="300"
              height="300"
              style="object-fit: cover;"
            />
            <div class="item__loader">
              <vue-content-loading
                v-if="!photo"
                :height="300"
                :width="300"
                :key="`photoLoading_${photoIndex}`"
                preserveAspectRatio="xMinYMin">
                <rect x="0" y="0" rx="2" ry="2" width="100%" height="100%"/>
              </vue-content-loading>
            </div>
          </div>
        </div>
      </div>
      <div class="item__tab productDescription">
        <div style="margin-bottom: 25px;">
          <template v-if="!loading">
            <div class="item__title">
              <h1 v-text="itemData.name"/>
              <span
                v-if="itemData.item_type === 'hotel' && itemData.fullVegan"
                class="item__badge"
                v-text="'100% Vegan'"
              />
              <AddToCartButton
                v-if="itemData.item_type === 'room' || itemData.item_type === 'offer'"
                :itemData="itemData"
                :monthlySubscription="itemData.monthly_subscription"
                @addItemToCart="addItemToCart"
              />
            </div>
            <span class="productLikes">
              <span>
                <button
                  @click="likeProduct()"
                  class="transparentButton"
                  :disabled="!$store.getters.userLoggedIn"
                  :title="$store.getters.userLoggedIn ? 'Love it':'Please login to give it some love'"
                >
                  <i
                    class="fa-heart"
                    :class="itemData.user_upvoted ? 'fas likeAnimation' : 'fal'"
                  />
                </button>
                <div
                  class="itemCard__likeCounter"
                  style="margin-right: 10px"
                  :class="{ 'itemCard__likeCounter--liked': itemData.user_upvoted }"
                >
                  <p>{{ itemData.upvotes }}</p>
                  <p>{{ itemData.upvotes }}</p>
                </div>
                <!-- <button
                  disabled
                  class="transparentButton"
                  title="No comments yet">
                  <i class ="fal fa-comment"/>
                </button>
                <div
                  class="itemCard__likeCounter"
                >
                  <p>0</p>
                </div> -->
              </span>
            </span>
            <br>
            <router-link
              class="owner_link"
              v-if="itemData.owner && itemData.owner.id.includes('org_') && 'packagedfood|freshfood|fashion|cosmetics|homeware|productoffering'.includes(itemData.item_type)"
              :to="`/o/${itemData.owner.slug}`"
            >
              {{ itemData.owner.name }}
            </router-link>
            <i v-if='itemData.item_type === "room"'>
              <br>
              {{ itemData.room_size }}m² &nbsp; • &nbsp;{{ itemData.beds.single }} single beds&nbsp; • &nbsp;{{ itemData.beds.double }} double&nbsp; • &nbsp; {{ itemData.beds.sofa_bed }} sofa&nbsp; ― &nbsp;up to {{ itemData.guest_limit }} guests
              <br>
            </i>
            <div class="item__description">
              <template v-if="showFullDescription">
                <p v-text="itemData.description"/>
              </template>
              <template v-else>
                <p v-text="trimmedDescription"/>
                <p><a @click.prevent='showFullDescription = true'>Read more...</a></p>
                <br>
              </template>
              <div
                v-if="itemData.item_type === 'offer' || itemData.item_type === 'room'"
                class="item__price">
                <p v-if="itemData.price">
                  <span>Price {{ itemData.item_type === 'room' ? 'per night':'' }}: </span>
                  <span
                    style="margin-left: 7px"
                    v-text="currencyList[itemData.currency].uniqSymbol ? currencyList[itemData.currency].uniqSymbol.grapheme:itemData.currency"
                  />
                  <span style="font-size: 1.8em" v-text="getPrice(itemData.price, itemData.currency)"/>
                </p>
                <p v-if="itemData.fixed_period && !itemData.monthly_subscription">
                  <b>When?</b><br>
                  {{ itemData.fixed_period.start }} ― {{ itemData.fixed_period.end }}
                </p>
                <template v-else-if="!itemData.monthly_subscription">
                  <p v-if="itemData.sale_period">
                    <b>Sale duration</b><br>
                    {{ itemData.sale_period.start }} ― {{ itemData.sale_period.end }}
                  </p>
                  <p v-if="itemData.available_period">
                    <b>Availability</b><br>
                    {{ itemData.available_period.start }} ― {{ itemData.available_period.end }}
                  </p>
                </template>
                <p v-if="itemData.category">
                  <b>Category</b><br>
                  {{ itemData.category }}
                </p>
              </div>
            </div>
            <div style="display: flex; flex-wrap: wrap;">
              <div
                v-if="itemData.storage === 'Frozen' || itemData.storage === 'Chilled'"
                style="display: flex; align-items: center; margin-right: 10px; margin-bottom: 5px; color: #009DC1"
              >
                <i class="fal fa-snowflake" style="font-size: 30px"></i>
                <span style="margin-left: 10px">
                  {{ itemData.storage }}
                </span>
              </div>
              <div
                v-if="itemData.gluten_free"
                style="display: flex; align-items: center; margin-right: 10px; margin-bottom: 5px;"
              >
                <img src="@/assets/gluten-free.png" class="fal fa-snowflake" style="height: 30px">
                <span style="margin-left: 10px">
                  {{ 'Gluten Free' }}
                </span>
              </div>
              <div
                v-if="itemData.soy_free"
                style="display: flex; align-items: center; margin-right: 10px; margin-bottom: 5px;"
              >
                <img src="@/assets/soy-free.png" class="fal fa-snowflake" style="height: 30px">
                <span style="margin-left: 10px">
                  {{ 'Soy Free' }}
                </span>
              </div>
              <div
                v-if="itemData.peanut_free"
                style="display: flex; align-items: center; margin-right: 10px; margin-bottom: 5px;"
              >
                <img src="@/assets/peanut-free.png" class="fal fa-snowflake" style="height: 30px">
                <span style="margin-left: 10px">
                  {{ 'Peanut Free' }}
                </span>
              </div>
              <div
                v-if="itemData.nut_free"
                style="display: flex; align-items: center; margin-right: 10px; margin-bottom: 5px;"
              >
                <img src="@/assets/nut-free.png" class="fal fa-snowflake" style="height: 30px">
                <span style="margin-left: 10px">
                  {{ 'Nut Free' }}
                </span>
              </div>
            </div>
            <div
              v-if="itemData.offerings.length > 0"
              class="offeringlist__offeringsContainer"
            >
              <span v-if="!isSingleSellerMarketPlace" class="buy_from">Buy from:</span>
              <div
                v-for='(offering, index) in orderedOfferings'
                :key="offering.id"
                class="offeringlist__offeringline"
                v-show="(displayAllOfferings || index < 3)"
              >
                <span v-if="offering.owner && offering.owner.image && !isSingleSellerMarketPlace">
                  <router-link
                    :to="`/o/${offering.owner.slug}`"
                  >
                    <img
                      class="offeringlist__orgImage"
                      :src="offering.owner.image"
                      alt="logo"
                    >
                  </router-link>
                </span>
                <div
                  class="offeringlist__offering"
                >
                  <div>
                    <span>
                      {{ offering.name }}
                    </span>
                    <span class="offeringlist__offeringPrice">
                      <span
                        v-if="offering.compare_at_price > offering.price"
                        class="offeringlist__offeringPrice--old"
                      >
                        {{ offering.currency === 'GBP' ? '£' : offering.currency }}
                        {{ getPrice(offering.compare_at_price, offering.currency) }}
                      </span>
                      <span class="discountPercentage">
                        {{ calculateDiscount(offering, offering.currency) }}
                      </span>
                      <span class="offeringlist__offeringPrice__current">
                        <b>{{ offering.currency === 'GBP' ? '£' : offering.currency }}{{ getPrice(offering.price, offering.currency) }}</b>
                      </span>
                    </span>
                    <br v-if="offering.owner.delivery_note && offering.owner.delivery_note.length" />
                    <span
                      v-if="offering.owner.delivery_note && offering.owner.delivery_note.length"
                      class="offeringlist__delivery_note"
                      >({{ offering.owner.delivery_note }})</span>
                  </div>
                  <div>
                    <button
                      class="addToCart"
                      v-if="itemData.item_type !== 'article' && itemData.item_type !== 'post'"
                      @click.stop="itemData.offering = offering; $emit('addItemToCart', itemData)"
                      :disabled="offering.available_quantity <= 0 || categoryDisabled"
                    >
                      {{ offering.available_quantity > 0 || !categoryDisabled ? 'Add to cart' : 'Out of stock' }}
                    </button>
                  </div>
                </div>
              </div>
              <span
                v-if="!displayAllOfferings && orderedOfferings.length > 3"
                @click.stop="displayAllOfferings = true"
                style="cursor:pointer; text-decoration: underline; padding-bottom: 10px;"
              >
                Show More...
              </span>
            </div>
            <div v-if="itemData.department === 'Alcoholic Beverages'" style="padding-top: 10px;">
              This item contains alcohol, which is not for sale to people under the age of 18.
              <br>
              A signature and ID may be required on delivery
            </div>
            <div
              v-if="owner && owner.discount_info && owner.discount_info.length"
              class="item__discounts"
            >
              <b>
              <router-link
                :to="`/o/${owner.slug}`"
              >
                {{ owner.name }}
              </router-link>
                offers bulk discounts:
              </b>

              <div
                v-for="(discount, index) in owner.discount_info"
                :key="index"
              >
                When you spend £{{ getPrice(discount.min_spend, 'GBP') }} or more, get a {{ discount.discount_per }}% discount.
              </div>
            </div>
            <div class="item__tags">
              <router-link
                v-for="(tag, index) in itemData.tags"
                class="item__tag"
                :to="getLinkPath('tag/' + encodeURIComponent(tag.trim()))"
                :key="`${tag}${index}`"
              >
                {{ `#${tag}` }}
              </router-link>
            </div>
          </template>
        </div>
        <USPBanner :isProductPage="true"/>
      </div>
      <div class="tabSelector">
        <template
          v-for="tab in tabs">
          <button
            v-if="(tab.item_type.includes(itemData.item_type) || tab.item_type === 'all') && tab.show()"
            class="tabSelector__button"
            :class="{'tabSelector__button--active': (activeTab === tab.name)}"
            @click="activeTab = tab.name"
            :key="tab.name">
            <p
              class="tabSelector__name"
              v-text="$ml.get(`item.${tab.name}`)"
            />
            <i class="tabSelector__icon fal"
              :class="`fa-${tab.icon}`"
            />
          </button>
        </template>
      </div>
      <div class="item__tabs">
        <div
          v-if="activeTab === 'about' && !loading"
          class="item__tab"
        >
          <vue-markdown v-if="itemData.content">{{ itemData.content }}</vue-markdown>
          <p v-else>Information not available, we are working hard to make this information complete. If you own this item you can add this information by claiming it <a :href="`${siteConstants.siteUrl}/account`">here</a></p>
        </div>
        <div
          v-if="activeTab === 'nutritional_facts' && !loading"
          class="item__tab"
        >
          <Nutritions
            v-if="itemData.nutrition_fact && Object.keys(itemData.nutrition_fact).length"
            :nutritions="itemData.nutrition_fact"
          />
          <p v-else style="text-align: left">
            There is no nutrition data yet for this product, but we are working on it!
          </p>
        </div>
        <div
          v-if="activeTab === 'ingredients' && !loading"
          class="item__tab"
        >
          <Ingredients v-if="itemData.ingredients" :ingredients="itemData.ingredients" />
          <p v-else>Information not available, we are working hard to make this information complete. If you own this item you can add this information by claiming it <a :href="`${siteConstants.siteUrl}/account`">here</a></p>
        </div>
        <div
          v-else-if="activeTab === 'opening_hours'"
          class="item__tab">
          <center>{{ itemData.item_type === 'room' ? 'Reception opening hours:':'' }}</center>
          <table>
            <tbody>
              <tr>
                <th>
                  <p v-text="$ml.get('item.day')"/>
                </th>
                <th>
                  <p v-text="$ml.get('item.hours')"/>
                </th>
              </tr>
              <tr
                v-for="day in itemData.openingHours"
                :key="day.day">
                <td v-text="$ml.get(`weekDays.${weekDays[day.day]}`)"/>
                <td v-if="day.start !== day.end">
                  <span v-text="`${day.start} - ${day.end}`"/>
                  <span v-if="day.is_overnight">  (Overnight)</span>
                </td>
                <td v-else v-text="'Open 24h'"/>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else-if="activeTab === 'amenities'" class="item__tab">
          <template v-if="itemData.item_type === 'hotel'">
            <div
              v-for="a in venueAmenities"
              class="item__amenity"
              :key="a.label">
              <p v-text="a.text"/>
              <i
                class="fal"
                :class="itemData.amenityMap[a.label] ? 'fa-check' : 'fa-times'"
              />
            </div>
          </template>
          <template v-if="itemData.room_facilities">
            <h2>Amenities</h2>
            <div class="item__amenities">
              <div
                v-for="(amenity, index) in itemData.room_facilities"
                class="item__amenity"
                :key="`amenity0_${index}`">
                <i :class="`fal fa-${ (translations[amenity] &amp;&amp; translations[amenity].icon) ? translations[amenity].icon:'check' }`"></i>
                <p>{{ translations[amenity] ? translations[amenity].label:amenity.replace('-', ' ') }}</p>
              </div>
            </div>
          </template>
          <template
            v-if="itemData.room_labels && itemData.room_labels.length > 0">
              <h2>Labels</h2>
              <div class="item__amenities">
                <div
                  v-for="(amenity, index) in itemData.room_labels"
                  class="item__amenity"
                  :key="`amenity1_${index}`">
                  <i
                    v-if="translations[amenity] && translations[amenity].icon"
                    :class="`fal fa-${translations[amenity].icon}`"
                  />
                  <p v-else>•&nbsp;</p>
                  <p>{{ translations[amenity] ? translations[amenity].label:amenity.replace('-', ' ') }}</p>
                </div>
              </div>
          </template>
          <template
            v-if="itemData.room_services && itemData.room_services.length > 0">
              <h2>Services</h2>
              <div class="item__amenities">
                <div
                  v-for="(amenity, index) in itemData.room_services"
                  class="item__amenity"
                  :key="`amenity1_${index}`">
                  <i
                    v-if="translations[amenity] && translations[amenity].icon"
                    :class="`fal fa-${translations[amenity].icon}`"
                  />
                  <p v-else>•&nbsp;</p>
                  <p>{{ translations[amenity] ? translations[amenity].label:amenity.replace('-', ' ') }}</p>
                </div>
              </div>
          </template>
          <template
            v-if="itemData.room_rules && itemData.room_rules.length > 0">
              <h2>Rules</h2>
              <div class="item__amenities">
                <div
                  v-for="(amenity, index) in itemData.room_rules"
                  class="item__amenity"
                  :key="`amenity1_${index}`">
                  <i
                    v-if="translations[amenity] && translations[amenity].icon"
                    :class="`fal fa-${translations[amenity].icon}`"
                  />
                  <p v-else>•&nbsp;</p>
                  <p>{{ translations[amenity] ? translations[amenity].label:amenity.replace('-', ' ') }}</p>
                </div>
              </div>
          </template>
        </div>
        <div v-else-if="activeTab === 'rooms'" class="item__tab">
          <Listing
            :ids="linkedItems.rooms.map(item => item.id)"
            :userLogged="$store.getters.userLoggedIn"
            :promotedOrgOfferings="['org_5ZfxxqrlbODXf5AO', 'org_dt7FOOwX6sjeat5k']"
            :disabledCollections="['vk_icecream']"
            @addItemToCart="$emit('addItemToCart', $event)"
            @openSnackbar="$emit('openSnackbar', $event)"
          />
        </div>
        <div class="item__tab" v-else-if="activeTab === 'offers'">
          <Listing
            :ids="linkedItems.offers.map(item => item.id)"
            :userLogged="$store.getters.userLoggedIn"
            :promotedOrgOfferings="['org_5ZfxxqrlbODXf5AO', 'org_dt7FOOwX6sjeat5k']"
            :disabledCollections="['vk_icecream']"
            @addItemToCart="$emit('addItemToCart', $event)"
            @openSnackbar="$emit('openSnackbar', $event)"
          />
        </div>
        <div v-else-if="activeTab === 'map'" class="item__tab">
          <p v-text="`${itemData.address.street_address}, ${itemData.address.city}, ${itemData.address.country}`"/>
          <a
            :href="`https://www.google.com/maps?q=${itemData.address}%20${itemData.name}`"
            target="_blank"
            v-text="'Open in Google Maps'"
          />
          <GoogleMap :geolocation="itemData.location"/>
        </div>
        <div
          v-else-if="activeTab === 'reviews'"
          class="item__tab item__tab--contact"
        >
          <Reviews :productData="itemData" @openMagicSidebar="openMagicSidebar"/>
        </div>
        <div
          v-else-if="activeTab === 'contact'"
          class="item__tab item__tab--contact">
          <div v-if="typeof itemData.urls !== 'undefined' && itemData.urls.website">
            <i class="fal fa-globe"/>
            <span>
              <a :href="itemData.urls.website" v-text="itemData.urls.website"/>
            </span>
          </div>
          <div v-if="typeof itemData.contact_phone !== 'undefined' && itemData.contact_phone">
            <br>
            <i class="fal fa-phone"/>
            <span v-text="itemData.contact_phone"/>
          </div>
          <div v-if="typeof itemData.contact_email !== 'undefined' && itemData.contact_email">
            <br>
            <i class="fal fa-envelope"/>
            <span v-text="itemData.contact_email"/>
          </div>
          <div class="item__socialIcons">
            <template v-if="typeof itemData.urls !== 'undefined' && itemData.urls.facebook">
              <a :href="itemData.urls.facebook" target="_blank">
                <i class="fab fa-facebook-square"/>
              </a>
            </template>
            <template v-if="typeof itemData.urls !== 'undefined' && itemData.urls.instagram">
              <a :href="itemData.urls.instagram" target="_blank">
                <i class="fab fa-instagram"/>
              </a>
            </template>
            <template v-if="typeof itemData.urls !== 'undefined' && itemData.urls.twitter">
              <a :href="itemData.urls.twitter" target="_blank">
                <i class="fab fa-twitter"/>
              </a>
            </template>
          </div>
        </div>
        <div
          class="relatedProducts"
          v-if="itemData.owner && itemData.owner.id.includes('org_') && ownerData.length > 0"
        >
          <Listing
            :ids="ownerData.map(item => item.id)"
            :displayModal="true"
            :userLogged="$store.getters.userLoggedIn"
            :promotedOrgOfferings="['org_5ZfxxqrlbODXf5AO', 'org_dt7FOOwX6sjeat5k']"
            :disabledCollections="['vk_icecream']"
            @addItemToCart="$emit('addItemToCart', $event)"
            @openSnackbar="$emit('openSnackbar', $event)"
          />
        </div>
        <div
          class="item__tab"
          v-if="itemData.item_type === 'hotel' || itemData.item_type === 'cafe' || itemData.item_type === 'restaurant' || itemData.item_type === 'room'"
        >
          <!-- <router-link :to="{ name: 'account' }"> -->
          <span>Are you the owner of this venue?</span>
          <br>
          <span><router-link to="/account">Claim ownership</router-link> and manage your business profile using our Business Manager</span>
          <!-- </router-link> -->
          <br>
          <br>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getName } from 'country-list'
import Utils from '../utils.js'
import VueContentLoading from 'vue-content-loading'
import VueMarkdown from 'vue-markdown'
import currencyList from '@/assets/currencyList.json'
import imgix from '@/services/imgix'
import _ from 'lodash'
import httpClient from '@/services/httpClient'
import siteConstants from '@/const.js'

export default {
  name: 'Item',
  components: {
    GoogleMap: () => import('@/components/GoogleMap.vue'),
    VueContentLoading,
    VueMarkdown,
    AddToCartButton: () => import('@/components/ItemPage/AddToCartButton.vue'),
    Reviews: () => import('@/components/Reviews.vue'),
    USPBanner: () => import('@/components/UspBanner.vue')
  },
  computed: {
    trimmedDescription () {
      if (this.itemData.description.length > 400) {
        return this.itemData.description.slice(0, 400) + '...'
      } else {
        return this.itemData.description
      }
    },
    orderedOfferings () {
      return _.orderBy(this.itemData.offerings, [function (result) { return result.available_quantity > 0 }, 'price'], ['desc', 'asc'])
    },
    isSingleSellerMarketPlace () {
      return process.env.VUE_APP_SINGLE_SELLER_MARKETPLACE === 'true'
    },
    categoryDisabled () {
      const disabledCollections = ['vk_icecream']
      if (disabledCollections.length === 0 || this.itemData.collections.length === 0) { return false }
      return disabledCollections.some((disCollection) => {
        return this.itemData.collections.includes(disCollection)
      })
    }
  },
  data () {
    return {
      siteConstants,
      slug: '',
      displayAllOfferings: false,
      loading: true,
      galleryScrollLeft: 0,
      showFullDescription: false,
      addToCartDropdownOpen: false,
      openedImage: null,
      currencyList: currencyList,
      activeTab: 'about',
      linkedItems: {
        rooms: [],
        offers: []
      },
      reviews: [],
      tabs: [
        {
          name: 'about',
          icon: 'newspaper',
          item_type: 'restaurant|hotel|cafe|shop|room|offer|packagedfood|freshfood|fashion|cosmetics|homeware|productoffering',
          show: () => true
        },
        {
          name: 'nutritional_facts',
          icon: 'clipboard-list',
          item_type: 'packagedfood|freshfood|productoffering',
          show: () => true
        },
        {
          name: 'ingredients',
          icon: 'carrot',
          item_type: 'packagedfood|productoffering',
          show: () => true
        },
        {
          name: 'amenities',
          item_type: 'hotel|room',
          icon: 'duck',
          show: () => true
        },
        {
          name: 'rooms',
          item_type: 'hotel',
          icon: 'bed',
          show: () => this.linkedItems.rooms.length > 0
        },
        {
          name: 'offers',
          item_type: 'restaurant|hotel|cafe|shop|room',
          icon: 'box',
          show: () => this.linkedItems.offers.length > 0
        },
        {
          name: 'opening_hours',
          item_type: 'restaurant|hotel|cafe|shop|room',
          icon: 'clock',
          show: () => true
        },
        {
          name: 'map',
          item_type: 'restaurant|hotel|cafe|shop',
          icon: 'map-marked',
          show: () => true
        },
        {
          name: 'reviews',
          item_type: 'restaurant|hotel|cafe|shop|room|offer|packagedfood|freshfood|fashion|cosmetics|homeware|productoffering',
          icon: 'stars',
          show: () => true
        },
        {
          name: 'contact',
          item_type: 'restaurant|hotel|cafe|shop',
          icon: 'mobile',
          show: () => true
        }
      ],
      weekDays: ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'],
      // TODO: DOWNLOAD THIS FROM THE /category/venue-amenity ROUTE
      // (NEED TO REMOVE THE "full-vegan" KEY)
      venueAmenities: [
        { 'label': 'bar', 'text': 'Bar' },
        { 'label': 'breakfast', 'text': 'Breakfast' },
        { 'label': 'car-park', 'text': 'Car park' },
        { 'label': 'dinner', 'text': 'Dinner' },
        { 'label': 'events-friendly', 'text': 'Good for events' },
        { 'label': 'free-wifi', 'text': 'Free WIFI' },
        { 'label': 'group-friendly', 'text': 'Good for groups' },
        { 'label': 'gym', 'text': 'Gym' },
        { 'label': 'hot-tub', 'text': 'Hot tub' },
        { 'label': 'lunch', 'text': 'Lunch' },
        { 'label': 'pets-allowed', 'text': 'Pets allowed 🐾' },
        { 'label': 'pool', 'text': 'Swimming pool' },
        { 'label': 'reception-24hr', 'text': '24-hour reception' },
        { 'label': 'restaurant', 'text': 'Restaurant' },
        { 'label': 'smoking-allowed', 'text': 'Smoking allowed' },
        { 'label': 'sport-facilities', 'text': 'Sports facilities' }
      ],
      translations: {
        shower: {
          label: 'Shower',
          icon: 'shower'
        },
        'private-bathroom': {
          label: 'Private bathroom',
          icon: 'bath'
        },
        towels: {
          label: 'Towels',
          icon: 'blanket'
        },
        linen: {
          label: 'Linen',
          icon: 'bed'
        },
        'free-wifi': {
          label: 'Free WiFi',
          icon: 'wifi'
        },
        kitchenette: {
          label: 'Kitchenette',
          icon: 'hat-chef'
        },
        kitchen: {
          label: 'Kitchen',
          icon: 'hat-chef'
        },
        heating: {
          label: 'Heating',
          icon: 'fireplace'
        },
        'electric-kettle': {
          label: 'Electric kettle',
          icon: 'plug'
        },
        'towel-change': {
          label: 'Towel change',
          icon: 'blanket'
        },
        'linen-change': {
          label: 'Linen change',
          icon: 'bed'
        },
        'luggage-storage': {
          label: 'Luggage storage',
          icon: 'boxes-alt'
        },
        balcony: {
          label: 'Balcony',
          icon: 'container-storage'
        },
        desk: {
          label: 'Desk',
          icon: 'chair-office'
        },
        tv: {
          label: 'TV',
          icon: 'tv-retro'
        },
        wardrobe: {
          label: 'Wardrobe',
          icon: ''
        },
        'clothes-rack': {
          label: 'Clothes rack',
          icon: ''
        },
        'toilet-paper': {
          label: 'Toilet paper',
          icon: 'toilet-paper'
        },
        telephone: {
          label: 'Phone',
          icon: 'phone'
        },
        cosmetics: {
          label: 'Cosmetics',
          icon: ''
        },
        terrace: {
          label: 'Terrace',
          icon: 'container-storage'
        },
        refrigerator: {
          label: 'Refrigerator',
          icon: 'temperature-frigid'
        },
        'seating-area': {
          label: 'Seating area',
          icon: 'chair'
        },
        sofa: {
          label: 'Sofa',
          icon: 'loveseat'
        },
        view: {
          label: 'Nice view',
          icon: 'mountain'
        },
        'cleaning-products': {
          label: 'Cleaning products',
          icon: 'broom'
        },
        'drying-rack': {
          label: 'Drying rack',
          icon: ''
        },
        'dining-table': {
          label: 'Dining table',
          icon: 'utensils'
        },
        kitchenware: {
          label: 'Kitchenware',
          icon: 'utensils-alt'
        }
      },
      ownerId: '',
      ownerData: [],
      owner: null,
      itemData: {
        monthly_subscription: false,
        name: '',
        item_type: 'item',
        tags: ['vegan', 'vege'],
        description: '',
        amenityMap: {},
        fullVegan: false,
        pictures: ['', '', '', '', '', '', '', '', '', ''],
        featured_picture: '',
        alwaysOpen: false,
        openingHours: []
      },
      addToCartStartDate: '',
      imgix
    }
  },
  metaInfo () {
    return {
      title: this.itemData.name,
      meta: [
        { vmid: 'keywords', name: 'keywords', content: this.itemData.tags.join`,` },
        // OpenGraph data
        { property: 'og:title', content: this.itemData.name + ` - ${siteConstants.siteName}` },
        { property: 'og:site_name', content: `${siteConstants.siteName}` },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `${siteConstants.siteUrl}/` + this.itemData.slug },
        { property: 'og:image', content: this.itemData.featured_picture },
        { property: 'og:description', content: this.itemData.description },
        // Twitter card
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: this.itemData.name + ` - ${siteConstants.siteName}` },
        { name: 'twitter:description', content: this.itemData.description },
        { name: 'twitter:image:src', content: this.itemData.featured_picture },
        // Google / Schema.org markup:
        { itemprop: 'name', content: this.itemData.name + ` - ${siteConstants.siteName}` },
        { itemprop: 'description', content: this.itemData.description },
        { itemprop: 'image', content: this.itemData.featured_picture }
      ]
    }
  },
  created () {
    this.slug = this.$route.params.slug
    if (this.slug.indexOf('/') > -1) {
      this.slug = this.slug.split('/')[this.slug.split('/').length - 1]
    }
    this.getItemData(function (item) {
      if (item.is_not_vegan || item.approval !== 'approved') {
        // this.$router.push({ name: '404' })
        this.$store.commit('updateNotFound')
        return
      }
      this.itemData = item
      this.itemData.monthly_subscription =
        this.itemData.monthly_subscription
          ? this.itemData.monthly_subscription
          : false
      this.itemData.amenityMap = {}
      this.itemData.fullVegan = false
      this.itemData.alwaysOpen = false
      this.itemData.openingHours = []
      this.ownerId = item.owner.id
      this.getOfferings()
      this.getOwnerItems()
      this.getOwner()
      this.getLinkedItems(item.id)
      try { this.gtmTrackItemView() } catch (err) { console.log(err) }

      switch (this.itemData.item_type) {
        case 'recipe':
          this.$router.push({ path: `/recipe/${this.slug}` })
          break
        case 'article':
          this.$router.push({ path: `/mag/${this.slug}` })
          break
      }

      if (typeof this.itemData.address !== 'undefined') {
        this.itemData.address.country = getName(this.itemData.address.country)
      }
      if (typeof this.itemData.amenities !== 'undefined') {
        this.itemData.amenityMap = {}
        Object.values(this.itemData.amenities).forEach(a => {
          if (a === 'full-vegan') {
            this.itemData.fullVegan = true
          } else {
            this.itemData.amenityMap[a] = true
          }
        })
      }
      if (typeof this.itemData.opening_hours !== 'undefined' || typeof this.itemData.reception_opening_hours !== 'undefined') {
        // TODO: HANDLE MULTIPLE SEASONS IN OPENING HOURS -- FOR THE
        // MOMENT ONLY DISPLAYING THE FIRST AVAILABLE
        let ohs
        if (this.itemData.item_type === 'room') {
          ohs = this.itemData.reception_opening_hours[0]
        } else {
          ohs = this.itemData.opening_hours[0]
        }

        // Always open case...
        if (ohs.periods.length === 1) {
          let p = ohs.periods[0]
          if (p.day === 0 && p.start === '0000' && typeof p.end === 'undefined') {
            this.itemData.alwaysOpen = true
            for (var d = 0; d < 7; ++d) {
              this.itemData.openingHours.push({ 'day': d, 'start': '0000', 'end': '0000' })
            }
          }
        }

        // Normal case...
        if (!this.itemData.alwaysOpen) {
          for (var i in ohs.periods) {
            let p = ohs.periods[i]
            let day = p.day
            let start = p.start.substr(0, 2) + ':' + p.start.substr(2)
            let end = null
            if (typeof p.end !== 'undefined') {
              end = p.end.substr(0, 2) + ':' + p.end.substr(2)
            }
            this.itemData.openingHours.push({ 'day': day, 'start': start, 'end': end, is_overnight: p.is_overnight })
          }
        }
      }
      this.itemData.tags = item.tags
      this.itemData.pictures = Object.values(this.itemData.pictures)
      this.$store.commit('setGalleryImages', this.itemData.pictures)
      if (this.itemData.description.length <= 300) {
        this.showFullDescription = true
      }
    }.bind(this))
  },
  methods: {
    gtmTrackItemView () {
      window.dataLayer.push({ ecommerce: null }) // Clear previous ecommerce object
      window.dataLayer.push({
        event: 'view_item',
        ecommerce: {
          // currency: itemData.currency, // ? currency should be in "3-letter ISO 4217" format
          // Price does not have decimal point on itemData object, therefore
          // an item with a price of 10£ will have itemData.price = 1000
          // value: selectedVariant.priceV2.amount,
          // currency: selectedVariant.priceV2.currencyCode,
          items: [
            {
              item_id: this.itemData.id,
              item_name: `${this.itemData.name}${this.itemData.owner ? ` - ${this.itemData.owner.name}` : ''}`,
              item_brand: this.itemData.owner ? this.itemData.owner.name : 'Seller' // Maybe add in the future as the owner org
              // price: this.itemData,
              // quantity: 1,
            }
          ]
        }
      })
    },
    setTab (name) {
      this.activeTab = name
    },
    onGalleryScroll (event) {
      this.galleryScrollLeft = event.target.scrollLeft
    },
    getItemData (callback) {
      httpClient.get(process.env.VUE_APP_API_URL + '/item/' + this.slug)
        .then(function (response) {
          this.loading = false
          callback(response.data)
          // console.log(response.data)
        }.bind(this))
        .catch(function (error) {
          this.loading = false
          // eslint-disable-next-line no-console
          console.error(error)
          // this.$router.replace({ name: '404' })
          this.$store.commit('updateNotFound')
        }.bind(this))
    },
    getLinkedItems (itemId) {
      httpClient.get(`${process.env.VUE_APP_API_URL}/item/${itemId}/links`)
        .then(response => {
          let links = response.data
          for (let link of links) {
            httpClient.get(`${process.env.VUE_APP_API_URL}/item/${link.target}`)
              .then(response2 => {
                let item = response2.data
                switch (item.item_type) {
                  case 'room':
                    this.linkedItems.rooms.push(response2.data)
                    this.activeTab = 'rooms'
                    break
                  case 'offer':
                    this.linkedItems.offers.push(response2.data)
                    break
                }
              })
          }
        })
    },
    getOfferings () {
      this.itemData.offerings = []
      httpClient.get(`${process.env.VUE_APP_API_URL}/item/${this.itemData.id}/links?page=1&per_page=100&link_format=full`)
        .then(response => {
          for (let link of response.data) {
            if (link.link_type === 'product-has-offerings') {
              httpClient.get(`${process.env.VUE_APP_API_URL}/item/${link.target}`)
                .then(response2 => {
                  this.itemData.offerings.push({
                    ...response2.data,
                    featured_picture: this.itemData.featured_picture,
                    pictures: this.itemData.pictures
                  })
                  this.itemData = { ...this.itemData } // To make the state refresh
                })
            }
          }
        })
    },
    getOwnerItems () {
      httpClient.get(`${process.env.VUE_APP_API_URL}/org/${this.ownerId}/items?format=full`).then(response => {
        this.ownerData = response.data
      })
    },
    getOwner () {
      if (this.ownerId.includes('usr_')) {
        httpClient.get(`${process.env.VUE_APP_API_URL}/user/${this.ownerId}`).then(response => {
          this.owner = response.data
        })
      } else {
        httpClient.get(`${process.env.VUE_APP_API_URL}/org/${this.ownerId}`).then(response => {
          this.owner = response.data
        })
      }
    },
    // getPrice () {
    //   // Converts a price from minor currency units to major ones
    //   return this.itemData.price / Math.pow(10, currencyList[this.itemData.currency]['fractionSize'])
    // },
    getPrice (price, currency) {
      // Converts a price from minor currency units to major ones
      return price / Math.pow(10, currencyList[currency]['fractionSize'])
    },
    addItemToCart () {
      this.$emit('addItemToCart', this.itemData)
    },
    cloneObject (obj) {
      let clone = {}
      for (let i in obj) {
        if (obj[i] != null && typeof (obj[i]) === 'object') {
          clone[i] = this.cloneObject(obj[i])
        } else {
          clone[i] = obj[i]
        }
      }
      return clone
    },
    getLinkPath (path) {
      return Utils.getLinkPath(path, this.$route)
    },
    scrollGalleryRight () {
      document.getElementById('gallery').scrollBy({
        left: 300,
        behavior: 'smooth'
      })
    },
    scrollGalleryLeft () {
      document.getElementById('gallery').scrollBy({
        left: -300,
        behavior: 'smooth'
      })
    },
    openMagicSidebar () {
      this.$emit('openMagicSidebar')
    },
    calculateDiscount (offering, currency) {
      if (offering.compare_at_price <= offering.price) {
        return ''
      }
      const originalPrice = offering.compare_at_price / Math.pow(10, currencyList[currency]['fractionSize'])
      const newPrice = offering.price / Math.pow(10, currencyList[currency]['fractionSize'])
      const discountPercentage =
        (1 -
          parseFloat(newPrice) / parseFloat(originalPrice)
        ) * 100
      return '-' + parseInt(discountPercentage) + '% '
    },
    async likeProduct () {
      if (this.itemData.user_upvoted) {
        try {
          this.itemData.user_upvoted = false
          this.itemData.upvotes--
          await httpClient.delete(`/item/${this.itemData.id}/upvote`)
        } catch {
          this.itemData.upvotes++
          this.itemData.user_upvoted = true
        }
      } else {
        try {
          this.itemData.user_upvoted = true
          this.itemData.upvotes++
          await httpClient.post(`/item/${this.itemData.id}/upvote`)
            .then(() => {
              this.$emit('openSnackbar', { message: '10 Points received', type: 'info' })
            })
        } catch {
          this.itemData.upvotes--
          this.itemData.user_upvoted = false
        }
      }
    }
  }
}
</script>

<style scoped lang="stylus">
.item
  width 100%
  box-sizing border-box
  text-align left
  z-index 1
  &--offer .item__container
    padding 30px 0
    margin 30px
    background white
  &__tab
    margin 0 auto
    padding 40px 50px 15px 50px
    &--contact
      padding-top 30px
      padding-bottom 70px
      span
        margin-left 8px
    .owner_link {
      color: #6D6589
      font-size: 19px
      font-weight: 500
    }
  &__description
    max-width 600px
    a
      cursor pointer
  &__tags
    max-width 600px
  &__title
    display inline-flex
    align-items center
    justify-content flex-start
    position relative
    .lightgray-text
      font-size 11px
      color: #6D6589
    h1
      font-family: 'Bebas Neue', sans-serif;
  &__price
    margin 13px 0
    p
      margin-bottom 0
  &__addToCartDropdown
    position absolute
    top calc(100% + 5px)
    left 0
    box-sizing border-box
    color black
    border 1px solid #ddd
    box-shadow 0 0 13px rgba(0, 0, 0, .12)
    width 190%
    padding 12px 12px 0 12px
    cursor default
    background-color white
    font-size 14px
    input[type=datetime-local]
      font inherit
      padding 5px
      margin 5px auto
  &__tag
    margin-right 10px
    display inline-block
    font-size 12px
    text-decoration none
    color #3B8EDE
  &__badge
    position absolute
    top calc(50% - 10px)
    right -110px
    width 90px
    font-size 11px
    text-align center
    padding 3px 10px
    box-sizing border-box
    border-radius 5px
    color #33cc55
    border 1px solid #33cc55
  &__photos
    position relative
    height 300px
    width 100%
    justify-content center
    overflow hidden
  &__discounts
    margin 10px 0
    a
      color inherit
  &__arrow
    position absolute
    right 10px
    width 30px
    color white
    font-size 34px
    top calc(50% - 25px)
    cursor pointer
    z-index 2
    background transparent
    border none
    &--left
      left 10px
  &__photosContainer
    width auto
    overflow-y hidden
    white-space nowrap
    height 330px
  &__photo
    display inline-block
    position relative
    cursor pointer
    width 300px
    height 300px
    background-color white
    overflow hidden
    z-index 1
    img
      position absolute
      z-index 2
      margin 0 auto
  &__socialIcons
    font-size 33px
    a
      margin-right 12px
  &__amenities
    margin-bottom 45px
    max-width 800px
    display flex
    flex-wrap wrap
  &__amenity
    display inline-flex
    margin-right 20px
    margin-top 10px
    align-items center
    justify-content flex-start
    background white
    border-radius 8px
    text-transform capitalize
    box-sizing border-box
    i
      margin-right 10px
      margin-left 10px
  &__loader
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    z-index 1
  .buy_from
    padding-top 20px
    padding-bottom 10px
    font-weight 600
  .offeringlist
    &__offeringsContainer
      display flex
      flex-direction column
      align-items start
      margin-top 10px
    &__offeringline
      display flex
      align-items center
    &__offering
      display: flex;
      align-items: center;
      background-color #fdfaec
      border 1px solid #f5d74b
      border-radius 4px
      margin 5px 0
      padding 8px 8px
      font-size 14px
    &__orgImage
      display: flex;
      height: 100%;
      max-width: 75px;
      max-height 35px
      padding-right: 10px
    &__offeringPrice
      margin-left 5px
      margin-right 5px
      font-weight bold
      &--old
        text-decoration line-through
    &__delivery_note {
      text-decoration: underline;
      font-style: italic;
    }
  table
    width auto
    margin 0 auto
    background white
    border-collapse collapse
    font inherit
    border-radius 8px
    overflow hidden
    tr
      background transparent
      border-bottom 1px solid #eee
      &:last-of-type
        border-bottom none
    th, td
      padding 10px 30px
      color #444
      text-transform capitalize
      font inherit
      font-weight 400
    th
      font-weight 800
.tabSelector
  display flex
  justify-content center
  align-items center
  margin 40px 50px 15px 50px
  border-top 1px solid #ddd
  &__button
    display inline-block
    border none
    width 120px
    margin 0 10px
    height 40px
    display flex
    background-color transparent
    align-items center
    justify-content center
    cursor pointer
    border-top 1px solid transparent
    p
      color #888
      font-size 13px
    &--active
      border-top 1px solid #222
      p
        color black
    &:focus
      outline none
    p
      opacity 1
      &:first-letter
        text-transform uppercase
  &__icon
    display none

.blankRectangle
  height 30px
  width 100%
  background transparent
.discountPercentage
  color red
  font-weight bold
.addToCart
  min-width: 100px;
  margin-left 5px
  background #f5d74b
  border none
  outline none
  color #94822b
  font-size 14px
  padding 8px 8px
  border-radius 6px
  font-weight bold
  cursor pointer
  &[disabled]
    cursor default
    background #dc6861
    color: #9f241c
.relatedProducts
  border-top 1px solid #ddd
  padding-top 30px
  margin 20px 50px 15px 50px
  /deep/.itemCard__title {
    font-family: 'Helvetica Regular', sans-serif
  }

.productDescription {
  display: flex;
  flex-direction: row;

  .productLikes {
    margin-top auto
    // padding 30px 5px 10px 5px
    width 100%
    display flex
    align-items center
    justify-content space-between
    user-select none

    .itemCard {
      &__likeCounter {
        margin-left 4px
        display inline-block
        width auto
        height 21px
        overflow hidden
        p {
          margin 0
          transform translateY(1px)
          transition 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)
          padding 0
        }
        &--liked {
          p {
            transform translateY(-18px)
          }
        }
      }
      &__like {
        display flex
        align-items center
        button {
          display block
          cursor pointer
          width 70px
          height 70px
          background transparent
          outline none !important
          border 1px solid rgba(179,179,179,0.4)
          font-size 16px
          &[disabled] {
            cursor default
            color inherit
          }
          i {
            opacity .4
          }
        }
        &--liked {
          button {
            i {
              opacity 1
            }
          }
        }
      }
    }

    .transparentButton {
      border none
      cursor pointer
      background transparent
      padding 0
      font inherit

      &[disabled] {
        cursor default
        color #999
      }
    }

    span {
      margin-right: 9px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  @media screen and (max-width: 960px) {
    flex-direction: column
  }
}

@media screen and (max-width: 768px)
  .item__tab
    padding 10px 10px 15px 10px
  .item__tabs
    text-align center
  .tabSelector {
    margin: 40px 0 15px 0;
  }
  .relatedProducts {
    margin 20px 10px 15px 10px
  }
  .tabSelector__name
    display none
  .tabSelector__icon
    font-size: 25px;
    display block
</style>
